export const confirmationModal = async (
  title: string,
  text: string,
  callback: () => Promise<void>,
  warning?: string
): Promise<void> => {
  window.loadingSpinner(true)

  const params = new URLSearchParams({ title, text })
  if (warning) params.set('warning', warning)

  try {
    const res = await fetch(`/dialogs/confirmation?${params.toString()}`)
    if (!res.ok) throw new Error()

    const html = await res.text()
    document.body.insertAdjacentHTML('beforeend', html)

    // Attach callback listener to the modal's confirm action
    const newModal = document.body.lastElementChild
    newModal?.addEventListener('confirmModalAction', async () => await callback())
  } catch {
    alert(window.I18n.unknown_error)
  }

  window.loadingSpinner(false)
}
