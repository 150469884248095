import Rails from '@rails/ujs'

// In production builds, this complains about already being loaded.
// Not an issue in development, so there's something weird going on here...
// UJS is being deprecated in future versions of Rails anyway (https://github.com/rails/rails/pull/43418),
//   so instead of trying to fix this, it's better to start migrating things to Turbo
if (!window._rails_loaded) {
  Rails.start()
}

// 3rd party libraries
import moment from 'moment'
window.moment = moment

import flatpickr from 'flatpickr'
flatpickr.l10ns.default.firstDayOfWeek = 1
flatpickr.defaultConfig.time_24hr = true
flatpickr.defaultConfig.altInputClass = ''
window.flatpickr = flatpickr

// NOTE: The Datatables imports need to be very specific and in a specific order to work!
// The default import statements provided by the download manager on their website didn't work for some reason
// Changing the main libraries to versions without the suggested "-dt" suffix seems to have fixed most of the issues
import JSZip from 'jszip'
window.JSZip = JSZip
import 'datatables.net'
import 'datatables.net-responsive'
import 'datatables.net-buttons'
import 'datatables.net-buttons/js/buttons.html5.mjs'
import 'datatables.net-buttons/js/buttons.print.mjs'

// Components
import { flash } from '~/web_components/flash_message'
import { confirmationModal } from '~/utils/confirmation_modal'
import { loadingSpinner } from '~/utils/loading_spinner'
window.flash = flash
window.loadingSpinner = loadingSpinner

// Utilities
import { Ssn } from '~/utils/ssn'
import { renderFormErrors } from '~/utils/form_errors'
window.confirmationModal = confirmationModal
window.Ssn = Ssn
window.renderFormErrors = renderFormErrors

// Define types for extensions on Window
declare global {
  interface Window {
    flash: typeof flash
    loadingSpinner: typeof loadingSpinner
    confirmationModal: typeof confirmationModal
    Ssn: typeof Ssn
    renderFormErrors: typeof renderFormErrors
    JSZip: typeof JSZip
  }
}
